import React from 'react'

import {
  Bullet,
  Button,
  Buttons,
  Columns,
  Divider,
  FormGroup,
  FormLayout,
  FormLayoutItem,
  Heading,
  List,
  ListItem,
  Main,
  Message,
  Paragraph,
  Spacer,
  Textarea,
  ThumbDownIcon,
  ThumbUpIcon,
  XIcon
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'
import { SectionHeading } from '../../components/SectionHeading'
import { ZoomedExample } from '../../components/ZoomedExample'
import { Link } from '../../components/Link'
import { Playground } from '../../components/Playground'
import { PatternExampleTallenna } from '../../examples/react/PatternExampleTallenna'
import { PatternExampleFailTallenna } from '../../examples/react/PatternExampleToimintoFail'
import { NoteItem } from '../../components/NoteItem'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content heading="Tallenna-toiminto">
    <Playground
      enableOverflow
      example={PatternExampleTallenna}
    />
    <Section title="Käyttötarkoitukset">
      <Message attentionColor="success">
        <Heading
          icon={
            <ThumbUpIcon
              color="success"
              size="lg"
            />
          }
          level={3}
          size={5}
        >
          Käytetään
        </Heading>
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>
            <strong>Lomakkeilla</strong>, jos tietoja voi muokata tallentamisen
            jälkeen, eikä toiminto julkaise tietoja yleisesti nähtäväksi.
          </ListItem>
          <ListItem>
            <strong>Dialogeissa</strong>, joissa muokataan tietoja.
          </ListItem>
        </List>
      </Message>
      <Message attentionColor="danger">
        <Heading
          icon={
            <ThumbDownIcon
              color="danger"
              size="lg"
            />
          }
          level={3}
          size={5}
        >
          Ei käytetä
        </Heading>
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>
            <strong>Lomakkeilla</strong>, jos
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                tietoja ei voi muokata lähettämisen jälkeen, tällöin käytössä{' '}
                <Link page="Lähetä-toiminto" />
              </ListItem>
              <ListItem>
                tiedot julkaistaan yleisesti nähtäväksi, ja tietoja voi muokata
                tallentamisen jälkeen (esim. työpaikkailmoitus tai palvelu).
                Tällöin käytössä <Link page="Julkaise-toiminto" />
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            <strong>Katselunäkymissä</strong>
          </ListItem>
          <ListItem>
            <strong>Käsittelynäkymissä</strong>
          </ListItem>
        </List>
      </Message>
    </Section>
    <Section title="Periaatteet">
      <Heading
        icon={
          <Bullet
            color="success"
            icon={
              <ThumbUpIcon
                color="white"
                size="md"
              />
            }
            size="lg"
          />
        }
        level={3}
        size={5}
      >
        Tietojen muokkaaminen: kyllä
      </Heading>
      <List variant="unordered">
        <ListItem>
          <strong>Käyttäjä voi muokata tallennettuja tietoja</strong>{' '}
          Muokkaa-toiminnolla.
        </ListItem>
        <ListItem>
          Vanhentuneiden tietojen poistamiselle voidaan tarvittaessa tarjota{' '}
          Arkistoi-toiminto.
        </ListItem>
      </List>
      <Divider />
      <Heading
        icon={
          <Bullet
            color="success"
            icon={
              <ThumbUpIcon
                color="white"
                size="md"
              />
            }
            size="lg"
          />
        }
        level={3}
        size={5}
      >
        Tietojen validointi: kyllä
      </Heading>
      <List variant="unordered">
        <ListItem>
          Lomakkeella syötetyt tiedot <strong>validoidaan aina</strong>{' '}
          toiminnon suorittamisen yhteydessä.
        </ListItem>
        <ListItem>
          Validointivirheistä ilmoitetaan käyttäjälle{' '}
          <Link page="Lomakkeen virheenkäsittely" /> -patternin mukaisesti
        </ListItem>
      </List>
      <Divider />
      <Heading
        icon={
          <Bullet
            color="danger"
            icon={
              <ThumbDownIcon
                color="white"
                size="md"
              />
            }
            size="lg"
          />
        }
        level={3}
        size={5}
      >
        Toiminnon varmistus: ei
      </Heading>
      <List variant="unordered">
        <ListItem>
          Toiminnon suorittamista <strong>ei varmisteta</strong> käyttäjältä.
        </ListItem>
        <ListItem>
          Erikoistapauksissa varmistus voidaan tarvittaessa tehdä
          varmistusdialogilla (
          <Code>
            <Link page="Modal" />
          </Code>
          )
        </ListItem>
      </List>
      <Divider />
      <Heading
        icon={
          <Bullet
            color="success"
            icon={
              <ThumbUpIcon
                color="white"
                size="md"
              />
            }
            size="lg"
          />
        }
        level={3}
        size={5}
      >
        Palaute toiminnon onnistumisesta: kyllä
      </Heading>
      <List variant="unordered">
        <ListItem>
          Toiminnon onnistumisesta <strong>ilmoitetaan aina</strong> käyttäjälle
          väliaikaisella ilmoituksella (
          <Code>
            <Link page="Notifications" />, status='success'
          </Code>
          )
        </ListItem>
        <ListItem>
          Viesti voi olla geneerinen &quot;Tiedot tallennettiin
          onnistuneesti&quot;, tarkentaa tallennetun tiedon tyypin tai tarjota
          tarkempaa kontekstikohtaista tietoa.
        </ListItem>
      </List>
      <Divider />
      <Heading
        icon={
          <Bullet
            color="success"
            icon={
              <ThumbUpIcon
                color="white"
                size="md"
              />
            }
            size="lg"
          />
        }
        level={3}
        size={5}
      >
        Palaute toiminnon epäonnistumisesta: kyllä
      </Heading>
      <List
        noMargin
        variant="unordered"
      >
        <ListItem>
          Toiminnon epäonnistumisesta <strong>ilmoitetaan aina</strong>{' '}
          käyttäjälle
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              <strong>
                Tallentamisen yhteydessä ilmenneestä validointivirheestä
              </strong>{' '}
              ilmoitetaan näkymään lisättävällä varoituksella (
              <Code>
                <Link page="Alert" />
              </Code>
              ). Tarkempi ohjeistus <Link page="Lomakkeen virheenkäsittely" />{' '}
              -patternissa
            </ListItem>
            <ListItem>
              <strong>
                Tallentamisen estävästä välittömästä järjestelmävirheestä
              </strong>{' '}
              ilmoitetaan varoitusdialogilla (
              <Code>
                <Link page="Modal" />
              </Code>
              ). Katso esimerkki kohdasta{' '}
              <a href="#tilat-ja-muunnelmat">Tilat ja muunnelmat</a>.
            </ListItem>
          </List>
        </ListItem>
      </List>
      <Divider />
      <Heading
        level={3}
        size={5}
      >
        Liittyvät toiminnot
      </Heading>
      <List variant="unordered">
        <ListItem>
          <strong>
            Tallentamisen vaihtoehtona tarjotaan aina jokin keskeyttävä
            toiminto:
          </strong>
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              <Link page="Keskeytä-toiminto" />
            </ListItem>
            <ListItem>Peruuta-toiminto</ListItem>
          </List>
        </ListItem>
        <ListItem>
          Tallentamisen vaihtoehtona voidaan tarjota{' '}
          <strong>tarvittaessa</strong>{' '}
          <Link page="Tallenna luonnoksena -toiminto" />.
        </ListItem>
      </List>
    </Section>
    <Section title="Rakenne ja toiminnallisuus">
      <Playground
        enableOverflow
        example={PatternExampleTallenna}
      />
      <NoteItem
        bullet="1"
        title={
          <>
            Painike (
            <Code>
              <Link page="Button" />, color='primary'
            </Code>
            )
          </>
        }
      >
        <Paragraph noMargin>Käyttäjän painaessa painiketta</Paragraph>
        <List variant="unordered">
          <ListItem>tiedot tallennetaan tietokantaan</ListItem>
          <ListItem>
            Käyttäjä ohjataan erikseen määriteltyyn näkymään. Esimerkiksi
            lomakkeen lähettämisen jälkeen käyttäjä voidaan ohjata näkymään,
            josta hän saapui lomakkeelle.
          </ListItem>
          <ListItem>
            Käyttäjälle ilmoitetaan onnistuneesta väliaikaisella ilmoituksella
            (kts. alla).
          </ListItem>
        </List>
      </NoteItem>
      <NoteItem
        bullet="2"
        title={
          <>
            Ilmoitus toiminnon onnistumisesta (
            <Code>
              <Link page="Notifications" />, status='success'
            </Code>
            )
          </>
        }
      >
        <Paragraph noMargin>Ilmoituksen teksti voi olla</Paragraph>
        <List variant="unordered">
          <ListItem>
            geneerinen &quot;Tiedot tallennettiin onnistuneesti&quot;,
          </ListItem>
          <ListItem>tarkentaa tallennetun tiedon tyypin tai</ListItem>
          <ListItem>
            tarjota lisäksi tarkempaa kontekstikohtaista tietoa, kuten
            julkaistun tiedon otsikon.
          </ListItem>
        </List>
      </NoteItem>
    </Section>
    <Section title="Tilat ja muunnelmat">
      <SectionHeading>Ilmoitus järjestelmävirheestä</SectionHeading>
      <Paragraph noMargin>
        Tallentamisen estävästä välittömästä järjestelmävirheestä ilmoitetaan
        varoitusdialogilla (
        <Code>
          <Link page="Modal" />
        </Code>
        ).
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          Modaalin otsikko ja teksti voivat olla esimerkin mukaisesti
          geneerisiä, tai antaa tarkempaa tietoa epäonnistumisen syystä.
        </ListItem>
        <ListItem>
          Sulje-toiminto palauttaa käyttäjän näkymään jossa hän painoi
          toimintopainiketta.
        </ListItem>
      </List>
      <Playground enableOverflow>{PatternExampleFailTallenna()}</Playground>
    </Section>
    <Section title="Sijoittuminen näkymässä">
      <Paragraph noMargin>
        Lomakkeella painike sijoitetaan lähtökohtaisesti sisältöalueen loppuun
        yhdessä muiden toimintopainikkeiden kanssa.
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          Jos näkymässä on useita koko näkymään liittyviä toimintoja,
          <strong>Tallenna sijoitetaan toiminnoista viimeiseksi.</strong>
        </ListItem>
        <ListItem>
          Sijoittelusta lomakkeella tarkemmin{' '}
          <Link page="Lomakkeen toiminnot" /> -patternissa.
        </ListItem>
      </List>
      <ZoomedExample>
        <Columns layout="single">
          <Main>
            <FormLayout>
              <FormLayoutItem>
                <FormGroup noMargin>
                  <Textarea label="Lomakekenttä" />
                </FormGroup>
              </FormLayoutItem>
            </FormLayout>
            <Spacer marginTop="xl">
              <Buttons align="center">
                <Button
                  iconLeft={<XIcon />}
                  variant="plain"
                >
                  Keskeytä
                </Button>
                <Button
                  color="primary"
                  variant="outline"
                >
                  Tallenna luonnoksena
                </Button>
                <Button color="primary">Tallenna</Button>
              </Buttons>
            </Spacer>
          </Main>
        </Columns>
      </ZoomedExample>
    </Section>
    <Section title="Esimerkkejä käyttötapauksista">
      <SectionHeading>Työmarkkinatorilla</SectionHeading>
      <List variant="unordered">
        <ListItem>
          Asiakkaan tilin hallinta: Omien tietojen muokkausdialogit
        </ListItem>
      </List>
      <SectionHeading>Asiantuntijan työmarkkinatorilla</SectionHeading>
      <List variant="unordered">
        <ListItem>Asiakkaan henkilötietojen muokkausdialogit</ListItem>
        <ListItem>Palvelutarvearvion luonti- ja muokkauslomake</ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
